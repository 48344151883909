import {
    FETCH_DATASOURCES,
    FETCH_CONFIGURED_REPORTS_LIST,
    FETCH_OVERALL_STATS,
    FETCH_OVERALL_RUN_STATS,
    FETCH_RECENT_ACTIVITIES,
    FETCH_EDIT_LOGS,
    FETCH_ROWS_MOVED_BY_DATE,
    FETCH_DATA_MOVED_BY_DATE,
    SET_CONFIGURED_REPORTS_LIST_IS_LOADING,
    SET_RECENT_ACTIVITIES_IS_LOADING,
    SET_EDIT_LOGS_IS_LOADING,
    SET_OVERALL_STATS_IS_LOADING,
    SET_OVERALL_RUN_STATS_IS_LOADING,
    SET_DATASOURCES_STATS_IS_LOADING,
    SET_ROWS_MOVED_BY_DATE_IS_LOADING,
    SET_DATA_MOVED_BY_DATE_IS_LOADING,
    SET_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING,
    FETCH_REVERSE_ROWS_MOVED_BY_DATE,
    SET_DATA_DESTINATIONS_STATS_IS_LOADING,
    FETCH_DATA_DESTINATIONS,
    SET_REVERSE_SYNC_REPORTS_LIST_IS_LOADING,
    FETCH_REVERSE_SYNC_REPORTS_LIST,
    TRANSFORMATIONS_LIST_LOADED,
    SET_TRANSFORMATIONS_IS_LOADING,
    SET_DBT_TRANSFORMATIONS,
    SET_DBT_TRANSFORMATIONS_LOADING,
    SET_ORCHESTRATIONS,
    SET_ORCHESTRATIONS_LOADING,
    SET_WORKSPACES_IS_LOADING,
    SET_WORKSPACES,
} from "../actionTypes/actionTypes";

const initialState = {
    dataSources: [],
    configuredReports: [],
    reverseSyncReports: [],
    overallStats: null,
    overallRunStats: null,
    recentActivities: [],
    editLogs: [],
    rowsMovedDatewise: [],
    dataMovedByDate: [],
    reverseRowsMovedDatewise: [],
    dataDestinations: [],
    transformations: [],
    overallStatsIsLoading: false,
    overallRunStatsIsLoading: false,
    dataSourcesIsLoading: false,
    configuredReportsIsLoading: false,
    recentActivitiesIsLoading: false,
    editLogsIsLoading: false,
    rowsMovedByDateIsLoading: false,
    dataMovedByDateIsLoading: false,
    reverseRowsMovedByDateIsLoading: false,
    dataDestinationsIsLoading: false,
    reverseSyncReportsIsLoading: false,
    isLoading: false,
    dbtTransformations: [],
    DBTisLoading: false,
    orchestrations: [],
    orchestrationsIsLoading: false,
    workspacesIsLoading: false,
    workspaces: [],
};

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATASOURCES:
            return {
                ...state,
                dataSources: action.payload,
            };
        case FETCH_CONFIGURED_REPORTS_LIST:
            return {
                ...state,
                configuredReports: action.payload,
            };
        case FETCH_OVERALL_STATS:
            return {
                ...state,
                overallStats: action.payload,
            };
        case FETCH_OVERALL_RUN_STATS:
            return {
                ...state,
                overallRunStats: action.payload,
            };
        case FETCH_RECENT_ACTIVITIES:
            return {
                ...state,
                recentActivities: state.recentActivities.concat(action.payload),
            };
        case FETCH_EDIT_LOGS:
            return {
                ...state,
                editLogs: action.payload,
            };
        case FETCH_ROWS_MOVED_BY_DATE:
            return {
                ...state,
                rowsMovedDatewise: action.payload,
            };
        case FETCH_DATA_MOVED_BY_DATE:
            return {
                ...state,
                dataMovedByDate: action.payload,
            };
        case FETCH_REVERSE_ROWS_MOVED_BY_DATE:
            return {
                ...state,
                reverseRowsMovedDatewise: action.payload,
            };
        case FETCH_DATA_DESTINATIONS:
            return {
                ...state,
                dataDestinations: action.payload,
            };
        case FETCH_REVERSE_SYNC_REPORTS_LIST:
            return {
                ...state,
                reverseSyncReports: action.payload,
            };
        case SET_OVERALL_STATS_IS_LOADING:
            return {
                ...state,
                overallStatsIsLoading: action.payload,
            };
        case SET_OVERALL_RUN_STATS_IS_LOADING:
            return {
                ...state,
                overallRunStatsIsLoading: action.payload,
            };
        case SET_DATASOURCES_STATS_IS_LOADING:
            return {
                ...state,
                dataSourcesIsLoading: action.payload,
            };
        case SET_CONFIGURED_REPORTS_LIST_IS_LOADING:
            return {
                ...state,
                configuredReportsIsLoading: action.payload,
            };
        case SET_RECENT_ACTIVITIES_IS_LOADING:
            return {
                ...state,
                recentActivitiesIsLoading: action.payload,
            };
        case SET_EDIT_LOGS_IS_LOADING:
            return {
                ...state,
                editLogsIsLoading: action.payload,
            };
        case SET_ROWS_MOVED_BY_DATE_IS_LOADING:
            return {
                ...state,
                rowsMovedByDateIsLoading: action.payload,
            };
        case SET_DATA_MOVED_BY_DATE_IS_LOADING:
            return {
                ...state,
                dataMovedByDateIsLoading: action.payload,
            };

        case SET_DATA_DESTINATIONS_STATS_IS_LOADING:
            return {
                ...state,
                dataDestinationsIsLoading: action.payload,
            };

        case SET_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING:
            return {
                ...state,
                reverseRowsMovedByDateIsLoading: action.payload,
            };
        case SET_REVERSE_SYNC_REPORTS_LIST_IS_LOADING:
            return {
                ...state,
                reverseSyncReportsIsLoading: action.payload,
            };
        case TRANSFORMATIONS_LIST_LOADED:
            return {
                ...state,
                transformations: action.payload,
            };
        case SET_TRANSFORMATIONS_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_DBT_TRANSFORMATIONS:
            return {
                ...state,
                dbtTransformations: action.payload,
            };
        case SET_DBT_TRANSFORMATIONS_LOADING:
            return {
                ...state,
                DBTisLoading: action.payload,
            };
        case SET_ORCHESTRATIONS_LOADING:
            return {
                ...state,
                orchestrationsIsLoading: action.payload,
            };
        case SET_ORCHESTRATIONS:
            return {
                ...state,
                orchestrations: action.payload,
            };
        case SET_WORKSPACES_IS_LOADING:
            return {
                ...state,
                workspacesIsLoading: action.payload,
            };
        case SET_WORKSPACES:
            return {
                ...state,
                workspaces: action.payload,
            };

        default:
            return state;
    }
};
