import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import AccountDetails from "./AccountDetails";
import UsersList from "./Users/UsersList";
import { useParams } from "react-router";
import UsageEmail from "./UsageEmail/UsageEmail";
import Workspaces from "./Workspace/index";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#FAFAFA",
    },
    root: {
        flexGrow: 1,
        marginLeft: 30,
        marginRight: 20,
    },
    tabPanel: {
        backgroundColor: "#FAFAFA",
    },
    tab: {
        textTransform: "capitalize",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 10,
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const AccountDetailsTabs = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { account_id } = useParams();
    const [user, setUser] = React.useState([]);

    const role = useSelector((state) => state.user.user.role);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    TabIndicatorProps={{ style: { backgroundColor: "#FD9567" } }}
                >
                    <Tab label="Account Details" {...a11yProps(0)} className={classes.tab} />
                    <Tab label="Users" {...a11yProps(1)} className={classes.tab} />
                    <Tab label="Usage Emails" {...a11yProps(2)} className={classes.tab} />
                    <Tab label="Workspaces" {...a11yProps(3)} className={classes.tab} />
                </Tabs>
            </AppBar>
            <Divider />
            <TabPanel value={value} index={0} className={classes.tabPanel}>
                <AccountDetails account_id={account_id} />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
                {/* <ZohoCustomer /> */}
                <UsersList account_id={account_id} />
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabPanel}>
                {/* <ZohoCustomer /> */}
                <UsageEmail account_id={account_id} user={user} setUser={setUser} />
            </TabPanel>
            <TabPanel value={value} index={3} className={classes.tabPanel}>
                <Workspaces account_id={account_id} />
            </TabPanel>
        </div>
    );
};

export default AccountDetailsTabs;
