import {
    FETCH_DATASOURCES,
    FETCH_CONFIGURED_REPORTS_LIST,
    FETCH_OVERALL_STATS,
    FETCH_OVERALL_RUN_STATS,
    FETCH_RECENT_ACTIVITIES,
    FETCH_EDIT_LOGS,
    FETCH_ROWS_MOVED_BY_DATE,
    FETCH_DATA_MOVED_BY_DATE,
    SET_CONFIGURED_REPORTS_LIST_IS_LOADING,
    SET_RECENT_ACTIVITIES_IS_LOADING,
    SET_EDIT_LOGS_IS_LOADING,
    SET_OVERALL_STATS_IS_LOADING,
    SET_OVERALL_RUN_STATS_IS_LOADING,
    SET_DATASOURCES_STATS_IS_LOADING,
    SET_ROWS_MOVED_BY_DATE_IS_LOADING,
    SET_DATA_MOVED_BY_DATE_IS_LOADING,
    SET_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING,
    FETCH_REVERSE_ROWS_MOVED_BY_DATE,
    SET_DATA_DESTINATIONS_STATS_IS_LOADING,
    FETCH_DATA_DESTINATIONS,
    SET_REVERSE_SYNC_REPORTS_LIST_IS_LOADING,
    FETCH_REVERSE_SYNC_REPORTS_LIST,
    TRANSFORMATIONS_LIST_LOADED,
    SET_TRANSFORMATIONS_IS_LOADING,
    SET_DBT_TRANSFORMATIONS,
    SET_DBT_TRANSFORMATIONS_LOADING,
    SET_ORCHESTRATIONS,
    SET_ORCHESTRATIONS_LOADING,
    SET_WORKSPACES_IS_LOADING,
    SET_WORKSPACES,
} from "../actionTypes/actionTypes";
import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
// import {
// 	getDatasourceStats,
// 	getReportsLogs,
// 	getOverAllStats,
// 	getOverAllRunStats,
// 	getRecentActivities,
// 	getReportEditLogs,
// 	getRowsMovedByDate,
// 	getDataMovedByDate
// } from "../../services/Api/api";
import { getAPIWrapper } from "../../utils/api-try-catch-wrapper/get-api-wrapper";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";
import ApiProvider from "services/Api/ApiProvider/ApiProvider";

const fetchDatasourceStats = (data, account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DATASOURCES_STATS_IS_LOADING,
        FETCH_DATASOURCES,
        {
            module: "accountDetails",
            method: "getDatasourceStats",
            apiData: {
                data: data,
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchReverseConnectorDashboardData = (data, account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DATA_DESTINATIONS_STATS_IS_LOADING,
        FETCH_DATA_DESTINATIONS,
        {
            module: "accountDetails",
            method: "getReverseConnectorDashboardData",
            apiData: {
                data: data,
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchReverseSyncDashboardData = (data, account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_REVERSE_SYNC_REPORTS_LIST_IS_LOADING,
        FETCH_REVERSE_SYNC_REPORTS_LIST,
        {
            module: "accountDetails",
            method: "getReverseSyncDashboardData",
            apiData: {
                data: data,
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchReportLogs = (data, account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_CONFIGURED_REPORTS_LIST_IS_LOADING,
        FETCH_CONFIGURED_REPORTS_LIST,
        {
            module: "accountDetails",
            method: "getConfiguredReportLogs",
            apiData: {
                data: data,
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchOverAllStats = (data, account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_OVERALL_STATS_IS_LOADING,
        FETCH_OVERALL_STATS,
        {
            module: "accountDetails",
            method: "getOverallStats",
            apiData: {
                data: data,
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchOverAllRunStats = (data, account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_OVERALL_RUN_STATS_IS_LOADING,
        FETCH_OVERALL_RUN_STATS,
        {
            module: "accountDetails",
            method: "getOverAllRunStats",
            apiData: {
                data: data,
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};
const fetchAccountOverAllStats = (data, account_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_OVERALL_STATS_IS_LOADING,
        FETCH_OVERALL_STATS,
        {
            module: "accountDetails",
            method: "getAccountOverallStats",
            apiData: {
                data: data,
                account_id: account_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchAccountOverAllRunStats = (data, account_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_OVERALL_RUN_STATS_IS_LOADING,
        FETCH_OVERALL_RUN_STATS,
        {
            module: "accountDetails",
            method: "getAccountOverAllRunStats",
            apiData: {
                data: data,
                account_id: account_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchRecentActivities = (page) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_RECENT_ACTIVITIES_IS_LOADING,
        FETCH_RECENT_ACTIVITIES,
        {
            module: "accountDetails",
            method: "getRecentActivities",
            apiData: {
                page: page,
            },
        },
        HttpErrorHandler
    );
    dispatch({ type: SET_RECENT_ACTIVITIES_IS_LOADING, payload: true });
    try {
        const apiService = new ApiProvider({
            module: "accountDetails",
            method: "getRecentActivities",
            apiData: {
                page: page,
            },
        });
        const res = await apiService.makeRequest();
        dispatch({ type: FETCH_RECENT_ACTIVITIES, payload: res.data.data });
        dispatch({ type: SET_RECENT_ACTIVITIES_IS_LOADING, payload: false });
    } catch (e) {
        dispatch({ type: SET_RECENT_ACTIVITIES_IS_LOADING, payload: false });
    }
};

const fetchEditReportLogs = (data, account_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_EDIT_LOGS_IS_LOADING,
        FETCH_EDIT_LOGS,
        {
            module: "accountDetails",
            method: "getReportEditLogs",
            apiData: {
                datasourceSlug: data.slug,
                reportId: data.reportId,
                account_id: account_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchRowsMovedByDate = (data, account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_ROWS_MOVED_BY_DATE_IS_LOADING,
        FETCH_ROWS_MOVED_BY_DATE,
        {
            module: "accountDetails",
            method: "getRowsMovedByDate",
            apiData: {
                data: data,
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchDataMovedByDate = (data, account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DATA_MOVED_BY_DATE_IS_LOADING,
        FETCH_DATA_MOVED_BY_DATE,
        {
            module: "accountDetails",
            method: "getDataMovedByDate",
            apiData: {
                data: data,
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchReverseRowsMovedByDate = (data, account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING,
        FETCH_REVERSE_ROWS_MOVED_BY_DATE,
        {
            module: "accountDetails",
            method: "getReverseRowsMovedByDate",
            apiData: {
                data: data,
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};
const fetchTransformations = (account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_TRANSFORMATIONS_IS_LOADING,
        TRANSFORMATIONS_LIST_LOADED,
        {
            module: "accountDetails",
            method: "getAllTransformations",
            apiData: {
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};
const fetchDbtTransformations = (account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DBT_TRANSFORMATIONS_LOADING,
        SET_DBT_TRANSFORMATIONS,
        {
            module: "accountDetails",
            method: "getDBTTransformation",
            apiData: {
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};
const fetchOrchestrations = (account_id, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_ORCHESTRATIONS_LOADING,
        SET_ORCHESTRATIONS,
        {
            module: "accountDetails",
            method: "getAllOrchestration",
            apiData: {
                account_id: account_id,
                workspace_id: workspace_id,
            },
        },
        HttpErrorHandler
    );
};

const fetchWorkspaces = (account_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_WORKSPACES_IS_LOADING,
        SET_WORKSPACES,
        {
            module: "accountDetails",
            method: "getAllWorkspaces",
            apiData: {
                account_id: account_id,
            },
        },
        HttpErrorHandler
    );
};
export {
    fetchDatasourceStats,
    fetchReportLogs,
    fetchOverAllStats,
    fetchOverAllRunStats,
    fetchRecentActivities,
    fetchEditReportLogs,
    fetchRowsMovedByDate,
    fetchDataMovedByDate,
    fetchReverseRowsMovedByDate,
    fetchReverseConnectorDashboardData,
    fetchReverseSyncDashboardData,
    fetchTransformations,
    fetchDbtTransformations,
    fetchAccountOverAllStats,
    fetchAccountOverAllRunStats,
    fetchOrchestrations,
    fetchWorkspaces,
};
